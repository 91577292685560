@import "/src/assets/styles/global.scss";
.chat-attachments {
  margin-right: auto;
  max-width: calc(100% - 180px);
  @media (max-width: 600px) {
    margin-bottom: 4px;
    width: 100%;
    max-width: 100%;
  }
  .chat-attachments__item {
    display: flex;
    align-items: center;

    .file {
      color: var(--primary-font-color);
      margin-right: 5px;
      // Text truncate
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 16px;
      text-align: center;
      margin-right: 8px;
      padding-top: 2px;
    }
    .icon {
      background: var(--gray-100);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      width: 30px;
      height: 30px;
      min-width: 30px;
      transition: all 0.3s ease 0s;
      border-radius: 100px;
      -webkit-box-pack: center;
      justify-content: center;
      margin-left: auto;
      cursor: pointer;
      padding: 0px !important;
      img {
        width: 14px;
      }
      &:hover {
        background-color: #eaeef3;
      }
    }
  }
}
.hidden-input {
  position: absolute;
  top: -99999px;
  left: -342432px;
  visibility: hidden;
  opacity: 0;
}
.chat-textbox {
  position: relative;
  width: 100%;
  height: auto;
  border: 2px solid $color-grey;
  border-radius: 10px;
  padding: 12px 20px;
  @media (max-width: 1366px) {
    padding: 8px;
  }
  &:hover {
    border-color: #bfced8;
  }
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &__loader {
    position: absolute;
    top: 0;
    right: 10px;
  }
  textarea {
    width: 100%;
    height: auto;
    // height: 390px;
    min-height: 80px;
    resize: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular", "Apple Color Emoji",
      "NotoColorEmoji-Regular";
    padding: 0;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
    overscroll-behavior: none;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #d1dde5 transparent !important; /* scroll thumb and track */
    transition: scrollbar-color 0.3s ease-out;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--field-label-color) !important ;
      opacity: 1 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
  }
  textarea:focus {
    outline: none;
  }
  textarea::placeholder {
    color: $color-grey-2;
  }
  .chat-textbox__actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
    .chat-textbox__actions--icon {
      cursor: pointer;
      background: var(--gray-100);
      width: 35px;
      height: 35px;
      border-radius: 100px;
      min-width: 35px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      margin-right: 8px;
      svg {
        cursor: pointer;
        color: #000c3d;
        font-size: 20px;
      }
      &--picker-wrapper {
        bottom: 40px;
        position: absolute;
        left: -10px;
        right: -10px;
      }
    }
    .chat-textbox__actions--send-button {
      display: flex;
      flex-direction: row-reverse;
      @media (max-width: 767px) {
        width: auto;
      }
      button {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-family: "TTCommons-Medium";
        padding-top: 4px;
      }
    }
  }
  &__reply {
    display: flex;
    margin-right: 16px;
    margin-bottom: 8px;
    &__divider {
      border: 1px solid #d1dde5;
      border-radius: 2px;
      margin-right: 12px;
    }
    &__content {
      width: 100%;
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-family: "TTCommons-Medium";
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 30px;
          height: 25px;
        }
        img {
          width: 20px;
          cursor: pointer;
        }
      }
      &__body {
        margin: 2px 0 0 0 !important;
        color: #51688b;
        font-family: "TTCommons-Regular", "Apple Color Emoji",
          "NotoColorEmoji-Regular";
        font-weight: 400;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .chat-textbox {
    textarea {
      height: auto;
    }
  }
}

.chat-input-textarea::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.chat-input-textarea::-webkit-scrollbar-track {
  background: $color-grey-8 !important; /* color of the tracking area */
  border-radius: 50px;
  // margin-bottom: 10px;
}

.chat-input-textarea::-webkit-scrollbar-thumb {
  background-color: $color-grey-4 !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 150px !important;
}

.chat-input-textarea::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.chat-input-textarea::-webkit-scrollbar-thumb:hover {
  border: 0;
}
