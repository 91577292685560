@import "./colors.scss";
@import "./mixins.scss";
@import "./typographies.scss";
@import "./breakpoints.scss";
/// default brand button
@mixin defaultBrandButton {
  @include wh(100%, 50px);
  @include radius(45px);
  @include background-color(var(--primary-color));
  @include TTCommonsMedium;
  @include text-color($color-white);
  @include center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 0.3rem;
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
    transition: all 0.3s;
  }
}

.o-chat-grid-container {
  height: 100%;
  > .col-lg-3 {
    @media (max-width: 1367px) {
      max-width: 35%;
      flex-basis: 35%;
    }
    @media (max-width: 992px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  > .col-lg-9 {
    @media (max-width: 1367px) {
      max-width: 65%;
      flex-basis: 65%;
    }
    @media (max-width: 992px) {
      max-width: 100%;
      flex-basis: 100%;
      margin-top: 16px;
    }
  }
}
.o-discover-network__row {
  margin-bottom: 12px;
}

@mixin discoverNetworkCardButton {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  @include center;
  @include TTCommonsRegular;
  border-radius: 100px;
  padding: 2px 15px 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  min-width: 101px;
  height: auto;
}

.fullscreen-enabled {
  background-color: white !important;
  padding: 40px 24px !important;
  @media (max-width: 767px) {
    padding: 16px !important;
  }
  > div:first-child {
    margin-bottom: 24px;
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  > div:nth-child(2) {
    > div {
      &:first-child {
        > div {
          > div {
            &:nth-child(2) {
              max-height: calc(100vh - 208px);
              @media (max-width: 767px) {
                max-height: calc(100vh - 152px);
              }
            }
          }
        }
      }
      &:nth-child(2) {
        > div {
          > div {
            &:nth-child(4) {
              height: calc(100vh - 142px);
              @media (max-width: 767px) {
                height: calc(100vh - 88px) !important;
              }
            }
            &:nth-child(5) {
              height: calc(100vh - 142px);
              @media (max-width: 767px) {
                height: calc(100vh - 88px) !important;
              }
            }
          }
        }
      }
    }
  }
}
.feather-maximize,
.feather-minimize {
  &:hover {
    scale: 1.5;
    transition: 0.4s all;
  }
  transform: 0.5s all;
}

.chat-window-maximize {
  &:hover {
    scale: 1.2;
  }
}

.field-group {
  label {
    color: var(--field-label-color);
    @include TTCommonsRegular;
    font-weight: 400;
    display: block;
    padding: 5px 0 0px 0px;
    margin: 0 0 6px 4px;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 12px;
  }
  .MuiFormControl-root {
    width: 100%;
    fieldset {
      border: 0;
    }
  }
  .a-mui-select__input {
    > div {
      padding: 4px 14px 0;
      @media (max-width: 1367px) {
        min-height: 50px;
      }
      > div:nth-child(2) {
        margin-bottom: 2px;
        > div {
          padding: 0 2px;
        }
      }
      &:nth-child(3) {
        > div {
          scrollbar-width: thin;
          -ms-overflow-style: auto;
          overscroll-behavior: none;
          scrollbar-width: thin; /* "auto" or "thin" */
          scrollbar-color: $color-grey transparent; /* scroll thumb and track */
          transition: scrollbar-color 0.3s ease-out;
          &::-webkit-scrollbar {
            width: 5px; /* width of the entire scrollbar */
          }

          &::-webkit-scrollbar-track {
            background: transparent !important; /* color of the tracking area */
            border-radius: 50px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-grey !important; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: none;
            height: 10px !important;
          }

          &::-webkit-scrollbar-corner {
            border-radius: 50%;
          }

          &::-webkit-scrollbar-thumb:hover {
            border: 0px;
          }
        }
      }
    }
    &:hover {
      > div {
        border-color: #bfced8;
      }
    }
  }
  + .field-group {
    margin-top: 25px;
    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
  &.Mui-error {
    input {
      border-color: #f7645f;
      &:hover {
        border-color: #f7645f;
      }
    }
  }
  .a-mui-datepicker {
    border: 2px solid $color-grey;
    @include radius(45px);
  }
}

/* mui-x-date-pickers START */

.MuiDateCalendar-root {
  height: fit-content !important;
  max-height: fit-content !important;
  .MuiPickersSlideTransition-root {
    min-height: 200px !important;
  }
  .MuiTypography-root,
  .MuiPickersCalendarHeader-labelContainer {
    color: var(--primary-font-color) !important;
    font-family: "TTCommons-Medium" !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 19px !important;
  }

  .current-month {
    flex: 1;
    text-align: center;
  }
}

.MuiDayCalendar-monthContainer > .MuiDayCalendar-weekContainer > .Mui-selected,
.MuiYearCalendar-root > .MuiPickersYear-root > .Mui-selected {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.MuiDayCalendar-monthContainer
  > .MuiDayCalendar-weekContainer
  > .MuiPickersDay-root:focus {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  border-color: var(--primary-color) !important;
}

.MuiPickersDay-root,
.MuiPickersYear-yearButton {
  color: var(--primary-font-color) !important;
  font-family: "TTCommons-Medium" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
  &:hover {
    background-color: rgba(
      var(--primary-color-r),
      var(--primary-color-g),
      var(--primary-color-b),
      0.25
    ) !important; // Assuming $primary-color is defined in Sass
  }
}

.MuiPickersDay-today {
  border-color: var(--primary-color) !important;
}

.MuiYearCalendar-root {
  &::-webkit-scrollbar {
    width: 7px; /* width of the entire scrollbar */
    height: 50px;
    transition: all ease-in-out 500ms;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important; /* color of the tracking area */
    border-radius: 50px;
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 10px 0 10px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-grey !important; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
    height: 150px !important;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0;
    background-color: $color-dark-gray !important; /* color of the scroll thumb */
  }
}

/* mui-x-date-pickers END */

// responsive-css
.ShowResponsiveDiv {
  display: none;
}
.only-for-mobile {
  @media (min-width: 993px) {
    display: none;
  }
}
.mt-1 {
  margin-top: 16px;
}
@media (max-width: 1367px) {
  .ShowResponsiveDiv {
    display: block;
  }
  .webinars-list-row {
    > .col-sm-3 {
      flex-basis: 35%;
      max-width: 35%;
      &:last-child {
        display: none;
      }
    }
    > .col-sm-6 {
      flex-basis: 65%;
      max-width: 65%;
    }
    .col-sm-12 {
      &:first-child {
        flex-basis: 35%;
        max-width: 35%;
      }
      &:last-child {
        flex-basis: 65%;
        max-width: 65%;
      }
    }
  }
  .resources-list-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 35%;
        flex-basis: 35%;
      }
      &:last-child {
        display: none;
      }
    }
    > .col-sm-6 {
      max-width: 65%;
      flex-basis: 65%;
    }
  }
  .student-service-list-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 35%;
        flex-basis: 35%;
      }
      &:last-child {
        display: none;
      }
    }
    > .col-sm-6 {
      max-width: 65%;
      flex-basis: 65%;
    }
  }
  .checklists-list-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 35%;
        flex-basis: 35%;
      }
    }
    > .col-sm-9 {
      max-width: 65%;
      flex-basis: 65%;
    }
  }
  .three-section-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 35%;
        flex-basis: 35%;
      }
      &:last-child {
        display: none;
      }
    }
    > .col-sm-6 {
      max-width: 65%;
      flex-basis: 65%;
    }
  }

  .two-section-row-with-spacing {
    > .col-sm-1 {
      display: none;
    }
    > .col-sm-3 {
      max-width: 35%;
      flex-basis: 35%;
    }
    > .col-sm-7 {
      max-width: 65%;
      flex-basis: 65%;
    }
  }
}
@media (max-width: 992px) {
  .webinars-list-row {
    > .col-sm-3 {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 16px;
      .ShowResponsiveDiv {
        display: none;
      }
    }
    > .col-sm-6 {
      flex-basis: 100%;
      max-width: 100%;
    }
    .col-sm-12 {
      &:first-child {
        flex-basis: 100%;
        max-width: 100%;
      }
      &:last-child {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
  .resources-list-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 100%;
        flex-basis: 100%;
      }
      &:last-child {
        display: none;
      }
    }
    > .col-sm-6 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  .student-service-list-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 100%;
        flex-basis: 100%;
      }
      &:last-child {
        display: none;
      }
    }
    > .col-sm-6 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  .checklists-list-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    > .col-sm-9 {
      max-width: 100%;
      flex-basis: 100%;
      margin-top: 16px;
    }
  }
  .three-section-row {
    > .col-sm-3 {
      &:nth-child(2) {
        max-width: 100%;
        flex-basis: 100%;
      }
      &:last-child {
        display: none;
      }
    }
    > .col-sm-6 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  .two-section-row-with-spacing {
    > .col-sm-1 {
      display: none;
    }
    > .col-sm-3 {
      max-width: 100%;
      flex-basis: 100%;
    }
    > .col-sm-7 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.checkbox-group {
  position: relative;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    margin: -2px 8px 0 0;
    position: relative;
    input {
      width: 16px;
      height: 16px;
      margin: 0;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
      &[value="true"] {
        + span {
          background-color: var(--primary-color);
          &:after {
            content: "";
            position: absolute;
            left: 6px;
            top: 2px;
            width: 3px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
          }
        }
      }
    }
    span {
      width: 16px;
      height: 16px;
      background-color: #f1f4f7;
      border-radius: 100px;
      border: 2px solid var(--primary-color);
    }
  }
  label {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17px;
    font-family: "TTCommons-Regular";
    display: block;
    padding-top: 2px;
    color: var(--secondary-font-color);
    margin-bottom: 0;
  }
}

.border-loader {
  border: 8px solid var(--primary-color); /* Light grey */
  border-top: 8px solid transparent; /* Blue */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
  margin: 16px auto 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.preview-url-post {
  text-decoration: none;
  display: block;
  .preview-nbox-wrappe {
    margin-top: 20px;
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
      text-decoration: none;
      margin: 0 0 0 0;
    }
    h4 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;
      text-decoration: none;
      margin: 4px 0 12px 0;
    }
    .topic-preview-video-section {
      > div {
        width: 100% !important;
        height: 280px !important;
      }
    }
    .img-wrap {
      img {
        width: 100%;
        max-height: 500px;
        object-fit: contain;
      }
    }
  }
}

.hide-scroll {
  overflow: hidden !important;
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    border-radius: 10px;
    background-color: #fafbfd;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.2);
    margin: 10px 0 0 0px;
    padding: 12px 20px 8px;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    color: #000c3d;
    max-width: fit-content;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    line-height: 17px;
  }
  .MuiTooltip-arrow {
    color: #fafbfd !important;
    transform: translate(20px, 0px) !important;
  }
}

.widget-wrapper {
  background: transparent;
}
.o-university-widget-wrapper {
  padding: 94px 0 0 0;
  @media (max-width: 930px) {
    padding: 75px 0 0 0;
  }
  .MuiBox-root {
    border: 0;
  }
  .o-university-widget-tabs {
    position: fixed;
    width: 100%;
    max-width: 906px;
    background: #fff;
    z-index: 5;
    top: 0;
    @media (max-width: 930px) {
      top: 0;
      max-width: calc(100% - 24px);
      margin: 0 auto;
      padding-top: 16px;
    }
    .MuiTabs-flexContainer {
      @media (max-width: 767px) {
        overflow-x: auto;
      }
      button {
        max-width: initial !important;
        padding: 12px 15px 12px;
        height: auto;
        min-height: auto;
        border-bottom: 3px solid #d1dde5;
        text-transform: initial;
        flex-grow: 1;
        flex-basis: 0;
        @media (max-width: 930px) {
          padding: 8px;
        }
        svg {
          margin: 0 0 6px 0;
          @media (max-width: 930px) {
            width: 32px;
            height: 32px;
          }
        }
        span {
          @media (max-width: 930px) {
            font-size: 18px;
            line-height: 16px;
          }
        }
      }
    }
    .MuiTabs-indicator {
      height: 3px;
      background-color: var(--primary-color);
    }
  }
  div[role="tabpanel"] {
    padding: 30px 0;
    @media (max-width: 930px) {
      padding: 16px 0;
    }
  }
}

.widget-chatlist-slider {
  .slick-prev {
    left: 3px;
    z-index: 1;
    &:before {
      color: var(--light-gray-color);
      font-size: 20px;
    }
  }
  .slick-next {
    right: 3px;
    z-index: 1;
    &:before {
      color: var(--light-gray-color);
      font-size: 20px;
    }
  }
  .slick-slider {
    border-radius: 10px;
    background-color: transparent;
    box-shadow: var(--box-shadow);
    padding: 0px 24px;
    &.accessibility-slider {
      .slick-slide {
        visibility: hidden;
      }
      .slick-active,
      .slick-current {
        visibility: visible;
      }
    }

    .slick-list {
      > .slick-track {
        display: flex !important;
        align-items: initial !important;
        > div {
          height: 100%;
          > div {
            height: 100%;
            > div {
              height: 100%;
              padding: 8px 12px;
              > div {
                height: 100%;
              }
            }
          }
        }
      }
    }
    .slick-disabled {
      opacity: 0;
      pointer-events: none;
    }
    .slick-arrow {
      &.slick-next {
        right: 10px;
      }
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    .slick-slide {
      > div {
        width: 100%;
        .card-wrapper {
          .slider-card {
            .card-content {
              width: 100%;
            }
          }
        }
      }
    }
    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
  .poweredby-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    padding-top: 45px;
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0;
      text-align: center;
    }
    img {
      max-width: 100px;
      vertical-align: middle;
      margin: -2px 0 0 8px;
    }
  }
}

.a-marker-world-map {
  width: 100%;
  margin-bottom: 48px;
  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
  .chart-wrapper {
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }
  .google-visualization-tooltip {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 1px rgba(0, 12, 61, 0.15) !important;
    padding: 15px 15px 12px;
    border: 0 !important;
    ul {
      margin: 0 !important;
      padding: 0 !important;
    }
    .google-visualization-tooltip-item {
      margin: 0px !important;
      padding: 0 !important;
      + .google-visualization-tooltip-item {
        margin-top: 2px !important;
      }
      span {
        font-family: "TTCommons-Medium" !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        color: var(--primary-font-color) !important;
      }
    }
  }
}

.mui-table-wrapper {
  &__no-data {
    height: 400px;
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__header {
      color: #000c3d;
      letter-spacing: 0;
      margin: 0 0 0 0;
      font-weight: 500;
      font-size: 22px;
      font-family: "TTCommons-Medium";
    }
    &__body {
      color: #000c3d;
      letter-spacing: 0;
      margin: 8px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      font-family: "TTCommons-Regular";
    }
  }
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
    padding: 0 10px 0 10px;
    thead {
      tr {
        th {
          color: #000c3d;
          font-family: "TTCommons-DemiBold";
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          padding: 4px 12px 20px;
          border: 0;
          white-space: nowrap;
          &:first-child {
            padding-left: 30px;
            @media (max-width: 767px) {
              padding-left: 16px;
            }
          }
          &:last-child {
            padding-right: 30px;
            @media (max-width: 767px) {
              padding-right: 16px;
            }
          }
        }
      }
    }
    tbody {
      border-radius: 10px;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      tr {
        td {
          color: #000c3d;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          padding: 26px 12px 19px;
          border: 0;
          background-color: rgba(209, 221, 229, 0.3);
          word-break: initial;
          @media (max-width: 1367px) {
            padding: 14px 12px 10px;
          }
          &:first-child {
            padding-left: 30px;
            @media (max-width: 767px) {
              padding-left: 16px;
            }
          }
          &:last-child {
            padding-right: 30px;
            @media (max-width: 767px) {
              padding-right: 16px;
            }
          }
        }
        &:nth-child(odd) {
          td {
            background-color: #fff;
          }
        }
        &:first-child {
          td {
            &:first-child {
              border-top-left-radius: 10px;
            }
            &:last-child {
              border-top-right-radius: 10px;
            }
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 10px;
            }
            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
  }
  .MuiPagination-ul {
    li {
      button {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        font-size: 18px;
        padding-top: 4px;
        display: flex;
        align-items: center;
        background: #edf2f7 !important;
        opacity: 1 !important;
        @media (max-width: 767px) {
          width: 28px;
          height: 28px;
          min-width: 28px;
          font-size: 15px;
        }
        svg {
          position: relative;
          top: -2px;
        }
        &.Mui-selected {
          background: #000c3d !important;
          color: #fff !important;
        }
      }
    }
  }
}

// Responsive search & filter design - START
.collapsible-section {
  @media (max-width: 992px) {
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 16px;
  }
  #collapsible-section-header {
    display: none;
    @media (min-width: 993px) {
      display: block;
    }
    @media (max-width: 992px) {
      display: flex;
      align-items: center;
      border-radius: 10px 10px 0 0;
      padding: 12px 8px 10px 16px;
      margin-bottom: 0;
    }
    h2 {
      @media (max-width: 992px) {
        padding: 0;
        font-size: 18px;
        line-height: 18px;
        min-height: auto;
        padding-top: 4px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        margin: 0;
      }
    }
    a {
      cursor: pointer;
      @media (min-width: 993px) {
        display: none;
      }
      @media (max-width: 992px) {
        margin-left: auto;
        padding: 4px 8px;
        display: flex;
        -webkit-tap-highlight-color: transparent;
      }
      svg {
        @media (max-width: 992px) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  #collapsible-section-search-filter {
    margin-top: 65px;
    @media (min-width: 993px) {
      margin-top: 16px;
    }
    > div {
      > div {
        padding: 0;
        background: transparent;
        box-shadow: none;
      }
    }
    @media (max-width: 992px) {
      margin-top: 0;
      display: none;
    }
    > div {
      @media (max-width: 992px) {
        border-radius: 0 0 10px 10px;
        box-shadow: none;
      }
    }
  }
  &--open {
    #collapsible-section-header {
      @media (max-width: 992px) {
        border-bottom: 1px solid #d1dde5;
      }
    }
    #collapsible-section-search-filter {
      display: block;
    }
  }
}
// Responsive search & filter design - END

// Common MUI popover styles
.mui-popover-wrapper {
  .MuiPopover-paper {
    max-width: 348px;
    border-radius: 1px 10px 10px 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    padding: 20px 20px 16px;
    margin-left: -8px;
    margin-top: 8px;

    p {
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: left;
      margin: 0 0 10px 0;
      color: var(--primary-font-color);
    }
  }
}

// Calendar styling
.m-webinar-calendar {
  padding: 0 !important;
  margin-left: 20px;
  @media (max-width: 991px) {
    margin-left: 0;
  }
  .fc-scrollgrid-section-header {
    .fc-col-header {
      thead {
        tr {
          th {
            border-color: #fff;
            border-width: 2px;
          }
        }
      }
    }
  }
  .fc-header-toolbar {
    margin: 0 0 15px 0 !important;
    .fc-toolbar-chunk {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      &:nth-child(1),
      &:nth-child(3) {
        display: none !important;
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        .fc-button {
          background: transparent;
          border: 0 !important;
          outline: none !important;
          color: var(--primary-font-color);
          font-size: 18px;
          padding: 0;
          margin: 0px 0 0 0;
          vertical-align: middle;
          box-shadow: none !important;
        }
        .fc-toolbar-title {
          font-size: 22px;
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          padding: 0 8px;
          font-weight: 500;
          min-width: 150px;
          text-align: center;
          height: 20px;
        }
      }
    }
  }
  .fc-view-harness {
    border: 2px solid #ffffff;
    border-radius: 10px;
    background-color: #f1f4f7;
    overflow: hidden;
    @media (max-width: 767px) {
      min-height: 550px;
    }
    .fc-daygrid {
      overflow-x: auto;
      .fc-scrollgrid {
        min-width: 750px;
        border: 0;
        thead {
          .fc-scrollgrid-section {
            td {
              .fc-scroller {
                table {
                  tr {
                    th {
                      border: 2px solid #ffffff;
                      .fc-scrollgrid-sync-inner {
                        height: auto;
                        padding: 20px 0px 14px;
                        white-space: nowrap;
                        a {
                          font-size: 18px;
                          font-weight: 600;
                          font-family: "TTCommons-DemiBold";
                          color: var(--primary-font-color);
                          letter-spacing: 0;
                          line-height: 19px;
                          text-align: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        tbody {
          .fc-scrollgrid-sync-table {
            min-height: 600px;
          }
          .fc-scrollgrid-section {
            .fc-scroller-harness {
              .fc-scrollgrid-sync-table {
                tbody {
                  td {
                    border: 2px solid #ffffff;
                    .fc-scrollgrid-sync-inner {
                      .fc-daygrid-day-top {
                        .fc-daygrid-day-number {
                          color: var(--primary-font-color);
                          font-family: "TTCommons-Medium";
                          font-size: 18px;
                          font-weight: 500;
                          letter-spacing: 0;
                          line-height: 19px;
                          text-align: right;
                          padding: 8px;
                        }
                      }
                    }
                    &.fc-day-today {
                      .fc-scrollgrid-sync-inner {
                        .fc-daygrid-day-top {
                          .fc-daygrid-day-number {
                            height: 26px;
                            width: 26px;
                            margin: 8px;
                            color: #fff;
                            padding: 4px 0 0 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .fc-popover {
        border-radius: 10px;
        background-color: #ffffff !important;
        box-shadow: var(--box-shadow) !important;
        border: 0 !important;
        overflow: hidden;
        .fc-popover-header {
          padding: 8px 8px 4px 8px;
          background: #f1f4f7;
          .fc-popover-title {
            color: var(--primary-font-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 16px;
            word-break: break-word;
            padding-top: 2px;
          }
          .fc-popover-close {
            color: var(--primary-font-color) !important;
            opacity: 1;
            margin-top: -2px;
          }
        }
      }
    }
  }
  .fc .fc-daygrid-day-number {
    padding: 10px;
    font-size: 20px;
    font-family: "TTCommons-Medium";
    color: var(--primary-color);
  }
  .fc .fc-scrollgrid-section-liquid > td {
    height: 100%;
    border-right: 0 !important;
    border-bottom: 0;
  }
  .fc .fc-scrollgrid-section > td {
    border-right: 0 !important;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;
    .fc-daygrid-day-number {
      background: var(--primary-color);
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 0 0 0;
      margin: 10px 10px 0 0;
    }
  }
  .fc .fc-daygrid-day-bottom {
    display: flex;
    font-size: 15px;
    color: var(--primary-font-color);
    justify-content: flex-start;
    margin: -6px 0 0 20px;
    font-family: "TTCommons-DemiBold";
    font-weight: 600;
  }
  .fc-theme-standard th {
    border-top: 0 !important;
    border-left: 0 !important;
    &:last-child {
      border-right: 0 !important;
    }
  }
  .view-header {
    table {
      &.fc-col-header {
        border: 0 !important;
      }
    }
    .cal-day-header {
      .fc-scrollgrid-sync-inner {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fc-col-header-cell-cushion {
          font-size: 18px;
          font-family: "TTCommons-Medium";
          color: var(--primary-color);
          letter-spacing: 0.7px;
          font-weight: 500;
          padding-top: 6px;
        }
      }
    }
  }
  .cal-event {
    cursor: pointer;
    &:hover {
      background: transparent;
    }
    > div {
      margin: 4px 2px;
      color: var(--primary-font-color);
      font-size: 15px;
      font-family: "TTCommons-Medium";
      border: 0;
      word-break: break-word;
      white-space: normal;
      text-align: left;
      line-height: 17px;
      display: flex;

      &:before {
        content: "";
        width: 12px;
        height: 12px;
        min-width: 12px;
        background: var(--primary-color);
        border-radius: 100px;
        display: inline-block;
        margin: 0px 3px 0 0;
      }
      // &.past-event {
      //   background: #e1e3e8;
      //   color: var(--secondary-font-color);
      // }
      // &.upcoming-event {
      //   background: #d9dde8;
      //   color: ${PRIMARY_COLOR};
      // }
      // &.selected-event {
      //   background: ${PRIMARY_COLOR};
      //   color: #fff;
      // }
      img.custom-rss-icon {
        height: 12px;
        width: 12px;
        top: 15px;
        right: 74px;
        position: absolute;
        cursor: pointer;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        strong {
          font-family: "TTCommons-DemiBold";
          font-weight: 600;
        }
      }
    }

    .fc-daygrid-event-dot,
    .fc-event-time {
      border: 0;
      display: none;
    }

    .fc-event-title,
    .fc-event-title-container {
      align-items: center;
      text-align: center;
      color: var(--primary-color);
      font-size: 15px;
      font-family: "TTCommons-Regular";
      font-weight: normal;
      line-height: 15px;
    }
  }
}

.filled-star-facelift,
.border-star-facelift {
  width: 22px;
  height: 22px;
  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
}

.subnav-layout {
  padding-top: 56px;
  @media (max-width: 992px) {
    padding-top: 42px;
  }
  @media (max-width: 767px) {
    padding-top: 50px;
  }
}

// Google maps cluster marker color
.yNHHyP-marker-view .IPAZAH-content-container.HJDHPx-interactive > * {
  fill: var(--primary-color);
}

.course-mainpage {
  margin-top: 1rem;
  > .MuiGrid-container {
    .MuiGrid-root {
      .title-primary {
        margin: 20px auto;
        color: var(--primary-font-color);
        font-family: "TTCommons-DemiBold";
        font-size: 22px;
        letter-spacing: 0;
        line-height: 24px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      &.MuiGrid-grid-xs-3 {
        .title-primary {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      &.MuiGrid-grid-xs-9 {
        .title-primary {
          @media (max-width: 767px) {
            text-align: center;
          }
        }
      }
    }
  }

  .categories {
    ul {
      margin: 0;
      list-style: none;
      padding: 0;
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      .category-item {
        a {
          padding: 10px 15px 10px 15px;
          text-decoration: none;
          box-shadow: var(--box-shadow);
          background: var(--white-color);
          color: var(--primary-font-color);
          border-radius: 100px;
          min-height: 50px;
          display: flex;
          align-items: center;
          transition: all 0.3s;
          &:hover {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          }
          &:focus {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          }
        }
        .header-primary {
          font-family: "TTCommons-Medium";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 16px;
          border-bottom: 1px solid transparent;
          padding-top: 6px;
        }
        + .category-item {
          margin-top: 10px;
          @media (max-width: 767px) {
            margin-top: 0;
            margin-left: 8px;
          }
        }
        &.active {
          a {
            background-color: var(--primary-color);
            color: var(--white-color);
          }
          .header-primary {
            color: var(--white-color);
          }
        }
        @media (max-width: 767px) {
          margin: 0 0 8px 0;
        }
      }
    }
  }
  .iframe-content {
    width: 100%;
    @media (max-width: 767px) {
      padding-left: 0px;
    }
    iframe {
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      vertical-align: middle;
    }
  }
  .course-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    min-height: 40px;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 16px;
      flex-direction: column;
      gap: 10px;
    }
    .masterclass-reset {
      position: absolute;
      left: 0px;
      @media (max-width: 767px) {
        position: initial;
        margin-bottom: 8px;
      }
      padding: 15px 15px 10px 15px;
      text-align: center;
      text-decoration: none;
      box-shadow: var(--box-shadow);
      background-color: var(--white-color);
      color: var(--secondary-font-color);
      border-radius: 100px;
      display: flex;
      align-items: center;
      transition: all 0.3s;
      font-family: "TTCommons-Medium";
      width: fit-content;
      img {
        margin: 1px 4px 5px -4px;
        height: 15px;
        transform: rotate(180deg);
      }
    }
    .title-primary {
      margin: 20px auto;
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      @media (max-width: 767px) {
        margin: 0 auto;
      }
    }
  }
}

.topic-post {
  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 13px;
    &__name-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      row-gap: 6px;
    }
    &__name {
      display: flex;
      align-items: center;
      h4 {
        margin: 3px 0 0 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        font-weight: 100;
      }
    }
    &__tag {
      min-width: fit-content;
      padding: 6px 10px;
      height: 25px !important;
      margin: 0 0 0 0;
      font-family: "TTCommons-Regular";
      text-align: center;
      color: #ffffff;
      background: var(--link-color);
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      border-radius: 12.5px;
      &--mentor {
        color: #ffffff;
        background: #335cd4;
      }
      &--alumni,
      &--student,
      &--staff {
        color: var(--primary-font-color);
        background: #d7ddf0;
      }
      &--community-creator,
      &--external {
        color: var(--primary-font-color);
        background: var(--gray-100);
      }
      &--ambassador {
        color: #a80c06;
        background: rgba(247, 100, 95, 0.09);
      }
    }

    &__time {
      min-width: 72px;
      height: 16px !important;
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 19px;
      min-width: fit-content;
      flex-grow: 1;
      font-weight: 100;
    }
    &__options {
      position: relative;
      display: flex;
      gap: 10px;
      &__menu-icon {
        cursor: pointer;
      }
      &__menu {
        z-index: 40;
        position: absolute;
        right: 0px;
        top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;
        border-radius: 10px;
        background: #ffffff;
        box-shadow: var(--box-shadow);
        padding: 0px 15px;
        span {
          cursor: pointer;
          width: 100%;
          text-align: center;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 18px;
          padding: 20px 0px;
          + span {
            border-top: 2px solid #eaeef3;
          }
        }
      }
    }
  }
  &__topic {
    margin: 0px 0 13px 0;
    height: 35px;
    width: fit-content;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
    padding-inline: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    &__name {
      height: 15px;
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
  .header-primary {
    &__content {
      h3 {
        margin: 0 0 10px 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular", "Apple Color Emoji",
          "NotoColorEmoji-Regular";
        font-size: 19px;
        letter-spacing: 0;
        line-height: 28px;
        white-space: break-spaces;
        font-weight: 100;
      }
      &__read-more-less {
        margin: 10px 0px;
        padding: 10px 12px;
        height: 35px !important;
        cursor: pointer;
        height: 35px;
        border-radius: 45px;
        background-color: #e8eef2;
        min-width: fit-content;
        height: 19px;
        width: 76px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
      &__attachment {
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
    &__url {
      margin-bottom: 10px;
      background: var(--gray-100);
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      &__image {
        margin-bottom: -7px;
        img {
          width: 100%;
        }
      }
      &__title {
        margin: 10px !important;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular", "Apple Color Emoji",
          "NotoColorEmoji-Regular";
        font-size: 20px;
        letter-spacing: 0;
        line-height: 25px;
        white-space: break-spaces;
        font-weight: 100;
      }
      &__description {
        padding-inline: 10px;
        display: inline-block;
        color: var(--secondary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 17px;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }
  .image-box2 {
    margin-bottom: 10px;
    .image {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .topic-preview-video-section {
    width: 100%;
    .topic-preview-video-section > div {
      width: 100% !important;
    }
  }
  .media {
    width: 100%;
    video {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
    .title {
      margin: 0 0 10px 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      letter-spacing: 0;
      line-height: 24px;
    }
    .image-box {
      display: flex;
      align-items: center;
      .image {
        width: 112px;
        min-width: 112px;
        height: 67px;
        overflow: hidden;
        border-radius: 10px;
        margin: 0 12px 0 0;
        img {
          width: 100%;
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
      }
      .title-box {
        .title {
          color: var(--primary-color);
          margin: 0;
        }
      }
    }
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    row-gap: 8px;
    position: relative;
    &__replies,
    &__comment {
      cursor: pointer;
      display: flex;
      height: 35px;
      padding: 10px 10px 0px 10px;
      border-radius: 45px;
      background: #f5f7f9;
      img {
        width: 30px;
        height: 30px;
        margin: -8px 0 0 -4px;
      }
      span {
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
    &__emoji {
      img {
        cursor: pointer;
      }
      &__picker {
        width: 100%;
        position: absolute;
        top: 45px;
        left: 0px;
        z-index: 1;
      }
    }
    &__reaction {
      cursor: pointer;
      display: flex;
      height: 35px;
      padding: 10.25px;
      border-radius: 45px;
      background: #f5f7f9;
      span {
        font-family: "TTCommons-Regular", "Apple Color Emoji",
          "NotoColorEmoji-Regular";
        color: var(--primary-font-color);
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  .comment-section {
    margin-top: 20px;
    .comment-list {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      .item {
        &__saperator {
          border: 1px solid var(--gray-100);
          border-radius: 10px;
          margin-bottom: 12px;
        }
        &__title {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 6px;
          &__avtar {
            .MuiAvatar-root {
              width: 40px;
              height: 40px;
            }
            margin: -2px 0 0 0;
            .initial-avatar {
              margin: 0;
              font-size: 20px;
              padding-top: 4px;
            }
          }
          &__name {
            color: var(--primary-font-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            font-weight: 500;
            line-height: 17px;
          }
          &__user-type {
            min-width: fit-content;
            padding: 6px 10px;
            height: 25px !important;
            margin: 0 0 0 0;
            font-family: "TTCommons-Regular";
            text-align: center;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 16px;
            border-radius: 12.5px;
            color: var(--primary-font-color);
            background: #d7ddf0;
            &--mentor {
              color: #ffffff !important;
              background: #335cd4 !important;
            }
          }
          &__community-creator {
            min-width: fit-content;
            padding: 6px 10px;
            height: 25px !important;
            margin: 0 0 0 0;
            font-family: "TTCommons-Regular";
            text-align: center;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 16px;
            border-radius: 12.5px;
            color: var(--primary-font-color);
            background: var(--gray-100);
          }
          &__time {
            color: var(--field-label-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            height: 12px;
            min-width: fit-content;
            flex-grow: 1;
            font-weight: 100 !important;
          }
          &__options {
            position: relative;
            display: flex;
            gap: 10px;
            &__menu-icon {
              cursor: pointer;
            }
            &__menu {
              z-index: 40;
              position: absolute;
              right: 0px;
              top: 40px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 200px;
              border-radius: 10px;
              background: #ffffff;
              box-shadow: var(--box-shadow);
              padding: 0px 15px;
              span {
                cursor: pointer;
                width: 100%;
                text-align: center;
                color: var(--primary-font-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 18px;
                padding: 20px 0px;
                + span {
                  border-top: 2px solid #eaeef3;
                }
              }
            }
          }
        }
        &__comment {
          margin: 0 0 0 50px;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular", "Apple Color Emoji",
            "NotoColorEmoji-Regular";
          font-size: 17px;
          letter-spacing: 0;
          line-height: 19px;
          white-space: pre-line;
        }
      }
    }
    .comment-load-more {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      span {
        width: fit-content;
        cursor: pointer;
        color: var(--primary-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
  }
  .comment-loader {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .comment-box {
    margin-top: 15px;
    &__text {
      width: 100%;
      > div {
        margin-bottom: 0;
      }
      .form-label {
        margin: 0 0 10px 4px !important;
        color: var(--primary-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        padding: 5px 0 0 0 !important;
      }
      .MuiInputBase-multiline {
        padding: 0;
        margin-bottom: 0;
        textarea {
          border: 2px solid var(--secondary-font-color);
          border-radius: 10px;
          background-color: #ffffff;
          padding: 21px 20px;
          box-sizing: border-box;
          font-size: 18px;
          line-height: 22px;
          font-family: "TTCommons-Regular", "Apple Color Emoji",
            "NotoColorEmoji-Regular";
          color: var(--primary-font-color);
          max-height: 100px;
          overflow: auto !important;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--field-label-color) !important;
            opacity: 1;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: var(--field-label-color) !important;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: var(--field-label-color) !important;
            opacity: 1;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: var(--field-label-color) !important;
            opacity: 1;
          }
          // --Focus style
          &:focus {
            border-color: var(--secondary-font-color) !important;
          }
          &:hover {
            border-color: #bfced8;
          }
          &:focus {
            border-color: var(--secondary-font-color);
          }
          /* scroll styling start */
          scrollbar-width: thin !important;
          scrollbar-color: var(--secondary-font-color) transparent; /* scroll thumb and track */
          &::-webkit-scrollbar {
            width: 7px; /* width of the entire scrollbar */
            transition: all ease-in-out 500ms;
          }
          &::-webkit-scrollbar-track {
            background: transparent !important; /* color of the tracking area */
            border-radius: 50px;
            width: 7px;
            -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
              29px 0px;
            margin: 10px 0 10px 0;
          }
          &::-webkit-scrollbar-thumb {
            background-color: var(
              --secondary-font-color
            ) !important; /* color of the scroll thumb */
            border-radius: 50px; /* roundness of the scroll thumb */
            height: 40px;
          }
          &::-webkit-scrollbar-corner {
            border-radius: 50%;
          }
          &::-webkit-scrollbar-thumb:hover {
            border: 0;
            background-color: var(
              --secondary-font-color
            ) !important; /* color of the scroll thumb */
          }
          /* scroll styling end */
        }
      }
    }
    &__action {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      position: relative;
      &__left {
        display: flex;
        gap: 5px;
        .emoji-picker-wrapper {
          height: 35px;
          .emoji-icon {
            cursor: pointer;
          }
          .emoji-picker {
            position: absolute;
            width: 100%;
            left: 0px;
            top: 40px;
            z-index: 10;
          }
        }
      }
      &__post {
        button {
          height: 35px;
          width: 78px;
          border-radius: 45px;
        }
      }
    }
  }
}

.edit-topic-modal {
  > div {
    > div {
      max-width: 670px !important;
    }
  }
  &__body {
    padding: 0px 20px 20px 20px;
    > div {
      box-shadow: initial;
      margin-bottom: 0px !important;
    }
  }
}

.delete-topic-modal {
  > div {
    > div {
      max-width: 480px !important;
    }
  }
  &__body {
    padding: 20px;
    display: flex;
    justify-content: center;
    button {
      width: 100px;
      height: 35px;
    }
  }
}

.report-topic-modal {
  > div {
    > div {
      max-width: 480px !important;
    }
  }
  .report-modal {
    padding: 20px;
    &__textbox {
      > div {
        margin-bottom: 0;
      }
      .form-label {
        margin: 0 0 10px 4px !important;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        padding: 5px 0 0 0 !important;
      }
      textarea {
        resize: none;
        width: 100% !important;
        border: 2px solid var(--light-gray-color);
        border-radius: 10px;
        background-color: #ffffff;
        padding: 21px 20px;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 22px;
        font-family: "TTCommons-Regular", "Apple Color Emoji",
          "NotoColorEmoji-Regular";
        color: var(--primary-font-color);
        max-height: 100px;
        overflow: auto !important;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--field-label-color) !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--field-label-color) !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--field-label-color) !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--field-label-color) !important;
        }
        // --Focus style
        &:focus {
          border-color: var(--secondary-font-color) !important;
        }
        &:hover {
          border-color: #bfced8;
        }
        &:focus {
          border-color: var(--secondary-font-color);
        }
        /* scroll styling start */
        scrollbar-width: thin !important;
        scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
          transition: all ease-in-out 500ms;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
            29px 0px;
          margin: 10px 0 10px 0;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(
            --light-gray-color
          ) !important; /* color of the scroll thumb */
          border-radius: 50px; /* roundness of the scroll thumb */
          height: 40px;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
          background-color: var(
            --secondary-font-color
          ) !important; /* color of the scroll thumb */
        }
        /* scroll styling end */
      }
    }
    &__button {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button {
        width: 100px;
        height: 35px;
      }
    }
  }
}

.comment-box {
  margin-top: 15px;
  &__text {
    width: 100%;
    > div {
      margin-bottom: 0;
    }
    .form-label {
      margin: 0 0 10px 4px !important;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      padding: 5px 0 0 0 !important;
    }
    textarea {
      resize: none;
      width: 100% !important;
      border: 2px solid var(--light-gray-color);
      border-radius: 10px;
      background-color: #ffffff;
      padding: 21px 20px;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 22px;
      font-family: "TTCommons-Regular", "Apple Color Emoji",
        "NotoColorEmoji-Regular";
      color: var(--primary-font-color);
      max-height: 100px;
      overflow: auto !important;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--field-label-color) !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--field-label-color) !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--field-label-color) !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--field-label-color) !important;
      }
      // --Focus style
      &:focus {
        border-color: var(--secondary-font-color) !important;
      }
      &:hover {
        border-color: #bfced8;
      }
      /* scroll styling start */
      scrollbar-width: thin !important;
      scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
      &::-webkit-scrollbar {
        width: 7px; /* width of the entire scrollbar */
        transition: all ease-in-out 500ms;
      }
      &::-webkit-scrollbar-track {
        background: transparent !important; /* color of the tracking area */
        border-radius: 50px;
        width: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px
          0px;
        margin: 10px 0 10px 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(
          --light-gray-color
        ) !important; /* color of the scroll thumb */
        border-radius: 50px; /* roundness of the scroll thumb */
        height: 40px;
      }
      &::-webkit-scrollbar-corner {
        border-radius: 50%;
      }
      &::-webkit-scrollbar-thumb:hover {
        border: 0;
        background-color: var(
          --secondary-font-color
        ) !important; /* color of the scroll thumb */
      }
      /* scroll styling end */
    }
  }
  &__action {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &__left {
      display: flex;
      gap: 5px;
      .emoji-picker-wrapper {
        height: 35px;
        .emoji-icon {
          cursor: pointer;
        }
        .emoji-picker {
          position: absolute;
          width: 100%;
          left: 0px;
          top: 40px;
          z-index: 10;
        }
      }
    }
    &__post {
      button {
        height: 35px;
        width: 78px;
        border-radius: 45px;
        .content {
          .button-spin {
            margin: 0 0 0 0 !important;
          }
        }
      }
    }
  }
}

.right-section {
  margin-top: 74px;
  position: relative;
  &__options {
    position: absolute;
    right: 20px;
    top: 20px;
    img {
      cursor: pointer;
    }
    &__list {
      list-style: none;
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      padding: 0 20px;
      width: 265px;
      position: absolute;
      z-index: 50;
      top: 30px;
      right: 0;
      background-color: #ffffff;
      li {
        margin: 0px;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        color: var(--primary-font-color);
        text-align: center;
        padding: 22px 0 18px;
        cursor: pointer;
        + li {
          border-top: 2px solid #eaeef3;
        }
      }
    }
  }
  &__members {
    &__header {
      margin: 40px 0 20px 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__creator {
      height: 80px;
      border-radius: 40px;
      background: #ffffff;
      box-shadow: var(--box-shadow);
      display: flex;
      align-items: center;
      padding-inline: 15px;
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        margin: 0 10px 0 0;
      }
      &__title {
        margin-left: 10px;
        &__line-one {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 4px;
          &__name {
            display: inline-block;
            color: var(--primary-font-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 18px;
            margin-top: 5px;
          }
          &__tag {
            min-width: fit-content;
            padding: 6px 10px;
            height: 25px !important;
            margin: 0 0 0 0;
            color: #ffffff;
            background: var(--link-color);
            font-family: "TTCommons-Regular";
            text-align: center;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 16px;
            border-radius: 12.5px;
          }
        }
        &__line-two {
          span {
            color: var(--secondary-font-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
      }
    }
  }
  &__saved {
    cursor: pointer;
    margin: 15px 0 15px 0;
    height: 50px;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    padding-inline: 20px;
    img {
      margin-right: 10px;
    }
    span {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      margin-top: 3px;
    }
    &:hover {
      box-shadow: var(--box-shadow-hover);
    }
  }

  .details {
    text-align: center;
    position: relative;
    padding: 0px !important;
    .MuiCardContent-root {
      padding: 0;
    }
    .max-width-wrap {
      .MuiAvatar-root {
        width: 90px;
        height: 90px;
        margin: -40px auto 12px;
      }
    }

    .title-primary {
      color: VAR(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0;
      text-align: center;
      margin: 0 0 4px 0;
      padding: 4px 0 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .created {
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      margin: 0 0 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      span {
        color: var(--primary-font-color);
        &:first-child {
          color: var(--field-label-color);
        }
      }
      .MuiAvatar-root {
        width: 25px;
        height: 25px;
        margin: -4px 0px 0 0px;
      }
    }
    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .MuiChip-root {
        border-radius: 12.5px;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        padding: 4px 8px 0;
        min-width: 69px;
        height: auto;
        span.MuiChip-label {
          padding: 0;
        }
      }
      &__gray {
        .MuiChip-root {
          background-color: var(--gray-100);
          color: var(--primary-font-color);
        }
      }
      &__blue {
        .MuiChip-root {
          background-color: var(--link-color);
          color: #ffffff;
        }
      }
      &__red {
        .MuiChip-root {
          background: rgba(247, 100, 95, 0.09);
          color: #a80c06;
        }
      }
    }
    .decription {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin: 0px auto 15px auto;
      .nlink-secondary {
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        margin-left: 5px;
        line-height: 17px;
      }
    }
    .participant {
      margin: 13px 0 10px 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      padding: 0;
    }
    .type {
      margin: 0px 0 13px 0;
      color: var(--link-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      padding: 0;
    }
    .members {
      .member {
        display: flex;
        align-items: center;
        .MuiAvatar-root {
          width: 40px;
          height: 40px;
          margin: 0 10px 0 0;
        }
        .content {
          display: flex;
          width: calc(100% - 60px);
          .header-primary {
            text-align: left;
            &.name {
              display: flex;
            }
            span,
            a {
              line-height: 17px;
              padding-top: 2px;
              display: inline-block;
              &.elips {
                margin-right: 6px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block !important;
                max-width: 100px;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                color: var(--primary-color);
                &:focus {
                  text-decoration: underline;
                }
              }
              &.network-participant-pointer {
                cursor: pointer !important;
                text-decoration: none;
              }
              &.creator {
                color: var(--secondary-font-color);
                letter-spacing: 0;
                font-family: "TTCommons-Regular";
                font-weight: normal;
                white-space: nowrap;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
              }
            }
          }
          .tag {
            margin-left: 6px;
            div {
              color: var(--secondary-font-color);
              font-size: 18px;
              font-family: "TTCommons-Regular";
            }
            .MuiChip-root {
              background: var(--light-gray-color);
              height: 20px;
              border-radius: 10.5px;
              background-color: #e5eaf7;
              color: var(--primary-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0px;
              line-height: 15px;
              text-align: center;
            }
          }
        }
        + .member {
          margin-top: 15px;
        }
      }
    }

    button.view-more {
      margin: 30px 0 0 0;
      padding: 4px 0 2px 0;
      height: auto;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      width: 100%;
      max-width: 164px;
      .content {
        min-width: auto;
      }
    }
    button.follow-topic {
      height: auto;
      width: 100%;
      max-width: 238px;
      padding: 20.85px 10px 14.85px 10px;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24.3px;
      text-align: center;
    }
    &.ntopics-cards-wrapper {
      max-width: 100%;
      overflow: visible;
      .card-close-btn {
        position: absolute;
        top: 0;
        right: 0;
      }
      .max-width-wrap {
        max-width: 285px;
        margin: 0 auto;
      }
    }
  }
  .no-data {
    margin-top: 60px;
  }
}

.topic-menu-dialog {
  .menu-dialog-content {
    margin-top: 20px;
    padding-bottom: 30px;
    .subheader {
      text-align: center;
      margin: -10px 0 30px 0;
      color: var(--primary-font-color);
      font-size: 15px;
      max-width: 100%;
      font-family: "TTCommons-Regular";
      line-height: 17px;
    }
    .content-box {
      margin: 0 25px 20px 25px;
      textarea {
        resize: none;
        width: 100%;
        border: 2px solid var(--light-gray-color);
        border-radius: 10px;
        background-color: #ffffff;
        padding: 21px 20px;
        font-size: 18px;
        line-height: 24px;
        font-family: "TTCommons-Regular", "Apple Color Emoji",
          "NotoColorEmoji-Regular";
        color: var(--primary-font-color);
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--field-label-color) !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--field-label-color) !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--field-label-color) !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--field-label-color) !important;
        }
        /* scroll styling start */
        scrollbar-width: thin !important;
        scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
          transition: all ease-in-out 500ms;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
            29px 0px;
          margin: 10px 0 10px 0;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(
            --light-gray-color
          ) !important; /* color of the scroll thumb */
          border-radius: 50px; /* roundness of the scroll thumb */
          height: 20px;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
          background-color: var(
            --secondary-font-color
          ) !important; /* color of the scroll thumb */
        }
        /* scroll styling end */
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      margin: 0 0 24px 0;
      button {
        width: 250px;
        height: 50px;
      }
    }
    .text-center {
      text-align: center;
      a {
        font-size: 18px;
        line-height: 16px;
        color: #607790;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid #607790;
        cursor: pointer;
        padding-top: 3px;
        transition: all 0.3s;
      }
    }
  }
  &.report-feedback {
    .menu-dialog-content {
      .btn {
        margin-bottom: 0;
        button {
          padding: 4px 0 0 0;
          max-width: 125px;
          height: 30px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }
}

.savedNetwork {
  cursor: pointer;
  border-radius: 25px;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  padding: 14px 20px 8px;
  text-decoration: none;
  width: 100%;
  min-height: 50px;
  margin-bottom: 20px;
  .section-content {
    .icon {
      margin: -2px 10px 0 0;
      display: flex;
      align-items: center;
    }
    .text {
      padding-top: 4px;
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
      padding-top: 4px;
      span {
        margin-left: 3px;
      }
    }
  }
}

.mytopic-mainpage {
  &__view-pinned {
    cursor: pointer;
    margin: 15px 0 15px 0;
    height: 50px;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    padding-inline: 20px;
    img {
      margin-right: 10px;
    }
    span {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      margin-top: 3px;
    }
    &:hover {
      box-shadow: var(--box-shadow-hover);
    }
  }
  &__recommendation-detail-wrapper {
    margin-top: 15px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 75px;
  }
}

.student-profile-card {
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 380px;
    width: 100%;
    > div {
      + div {
        margin-top: 10px;
      }
    }
  }

  &__profile_wrapper {
    width: 70px;
    height: 70px;
    margin-right: 0;
    border-radius: 100px;
    overflow: hidden;
    div {
      width: 100% !important;
      height: 100% !important;
    }
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      border-radius: 0;
    }
    .initial-avatar {
      padding-top: 10px;
      width: 100px;
      height: 100px;
      border-radius: 100px;
      font-size: 32px;
    }
  }

  &__name_wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    p {
      margin: 0;
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
      margin-bottom: 0;
      font-family: "TTCommons-Medium";
      padding-top: 7px;
    }
  }
  &__school_name {
    font-size: 15px;
    margin: 5px 0 0;
  }

  &__tags_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 10px !important;
  }
  &__tag {
    margin: 0;
    background-color: #f1f4f7;
    color: #000c3d !important;
    height: 25px;
    border-radius: 12.5px;
    font-family: "TTCommons-Medium";
    font-size: 15px;
    line-height: 29px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0;
    padding-inline: 7.5px;
    display: block;
    text-transform: capitalize;
    &--user {
      color: var(--primary-font-color) !important;
      background: #d7ddf0 !important;
    }
    &--admissions {
      background: rgba(247, 100, 95, 0.17) !important;
      color: #a80c06 !important;
    }
  }

  &__education_wrapper {
    max-width: 250px;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary-color);
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      margin-bottom: 0;
      font-family: "TTCommons-Medium";
      > img {
        margin: -6px 8px 0 0;
      }
    }
  }

  &__qualification_wrapper {
    p {
      margin-top: 0;
      margin-bottom: 0px;
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      font-family: "TTCommons-Medium";
    }
  }

  &__topic-intro-line {
    p {
      margin-top: 0;
      margin-bottom: 0px;
      color: var(--primary-font-color);
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      font-family: "TTCommons-Regular";
    }
  }

  &__location_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      img {
        margin: -4px 8px 0px 0;
        height: 15px;
        border-radius: 2px;
      }
      p {
        font-family: "TTCommons-Regular";
        color: var(--primary-font-color);
        font-size: 15px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
        margin: 0;
      }
    }
  }

  &__bio_wrapper {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        color: var(--primary-font-color);
        font-size: 15px;
        line-height: 17px;
        text-align: center;
        margin: 0;
        font-family: "TTCommons-Regular";
      }
      span {
        font-size: 15px;
        line-height: 13px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 1px solid var(--primary-font-color);
        cursor: pointer;
        padding-top: 3px;
        transition: all 0.3s;
      }
    }
  }

  &__linkedin_wrapper {
    a {
      cursor: pointer;
      text-decoration: underline;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 15px;
      font-family: "TTCommons-Regular";
      text-decoration: none;
      color: #607790;
      span {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        overflow: hidden;
        margin-bottom: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 0;
        }
      }
    }
  }
  &__school-name {
    p {
      margin-top: 0;
      margin-bottom: 0px;
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      font-family: "TTCommons-Medium";
    }
  }
}

.discover-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  gap: 10px;
  min-height: 50px;
  margin-bottom: 30px;
  @media (max-width: 790px) {
    margin-bottom: 0px;
  }
  &__title {
    height: fit-content;
    margin: 0 0 0 0;
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
  }
  &__back {
    position: absolute;
    top: 0px;
    left: 0px;
    @media (max-width: 790px) {
      position: initial;
    }
    button {
      background: #fff !important;
      box-shadow: var(--box-shadow) !important;
      width: fit-content;
      padding: 3px 21px 0 21px;
      border-radius: 100px;
      transition: all 0.3s;
      display: inline-block;
      text-transform: initial !important;
      &:hover {
        box-shadow: var(--box-shadow-hover) !important;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          margin: 0 5px 2px 0;
        }
        span {
          color: var(--field-label-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          font-family: "TTCommons-Medium";
          margin-top: 2px;
        }
      }
    }
  }
  &__new-topic {
    position: absolute;
    top: 0px;
    right: 0px;
    @media (max-width: 790px) {
      position: initial;
    }
    .shadow-btn {
      background: #fff !important;
      box-shadow: var(--box-shadow) !important;
      width: fit-content;
      padding: 5px 21px 0 21px;
      border-radius: 100px;
      transition: all 0.3s;
      display: inline-block;
      text-transform: initial !important;
      &:hover {
        box-shadow: var(--box-shadow-hover) !important;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 16px;
          height: 16px;
          margin-left: 5px;
          margin-bottom: 2px;
        }
        span {
          color: var(--primary-font-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          font-family: "TTCommons-Medium";
          margin-top: 2px;
        }
      }
    }
  }
}

.chat-reaction-tooltip {
  &__emoji {
    text-align: center;
    font-family: "TTCommons-Regular", "Apple Color Emoji",
      "NotoColorEmoji-Regular";
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 0 0;
  }
  &__text {
    margin: 8px 0 0 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    max-width: 300px;
  }
}
