@import "../../../assets/styles/global.scss";

.a-notification {
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  &__text-info {
    display: flex;
    align-items: center;
    min-width: 0;
    flex: 1;
    overflow: hidden;
    padding-right: 16px;
    @media (max-width: 767px) {
      width: 100%;
    }
    &__icon {
      display: flex;
      min-width: 50px;
      min-height: 50px;
      background: var(--gray-100);
      border-radius: 100px;
      height: 50px;
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        width: 40px;
        min-width: 40px;
        min-height: 40px;
        height: 40px;
        margin-right: 10px;
      }
      img {
        width: 100%;
      }
    }
    > div {
      overflow: hidden;
    }
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular", "Apple Color Emoji",
        "NotoColorEmoji-Regular";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
    }
    span {
      color: var(--secondary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 15px;
    }
  }
  &__action-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
    white-space: nowrap;
    padding-left: 16px;
    transition: all 0.3s ease 0s;
    @media (min-width: 1024px) {
      opacity: 0;
      visibility: hidden;
    }
    @media (max-width: 767px) {
      margin-left: 50px;
      padding-left: 0;
      margin-top: 2px;
    }
  }
  &:hover {
    > div {
      &:nth-child(2) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
